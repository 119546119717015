import React from "react"
import { graphql } from "gatsby"
import Button from "../../components/Button"
import Card from "../../components/Card"
import CustomerCard from "../../components/CustomerCard"
import CustomerLogo from "../../components/CustomerLogo"
import LabelText from "../../components/LabelText"
import Layout from "../../components/layout/Layout"
import Newsletter from "../../components/layout/Newsletter"
import SplitSection from "../../components/SplitSection"
import PressSection from "../../components/PressSection"
import StatsSection from "../../components/StatsSection"
import gigasetLogo from "../../svg/gigaset-logo-color.svg"
import loreallogo from "../../svg/loreal-logo.svg"
import telefonicaLogo from "../../svg/telefonica-logo.svg"
import { PrismicRichText } from "@prismicio/react"

const IndustryPage = ({ data }) => {
  const prismicHomepage = data.allPrismicIndustryPage.edges.slice(0, 1).pop()
  if (!prismicHomepage) return null
  return (
    <Layout>
      <section className="md:pt-16 lg:pt-16 xl:pt-16">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center pt-8 lg:text-left lg:w-1/2">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl leading-none font-semibold">
              {prismicHomepage.node.data.section_1[0].title.text}
            </h1>
            <p className="text-l lg:text-m lg:pr-12 mt-12 font-light">
              <PrismicRichText
                field={
                  prismicHomepage.node.data.section_1[0].description.richText
                }
              />
            </p>
            <div className="mt-8 md:mt-12">
              <Button size="xl" link="http://contact.chatchamp.com/testzugang">
                Jetzt testen
              </Button>
            </div>
            <div className="container mx-auto lg:mt-16">
              <p className="mt-4 text-gray-700">
                Unternehmen die Chatchamp vertrauen:
              </p>
              <div className="flex flex-row mt-4">
                <CustomerLogo src={loreallogo} />
                <CustomerLogo src={telefonicaLogo} />
                <CustomerLogo src={gigasetLogo} />
              </div>
            </div>
          </div>
          <div className="lg:w-1/2">
            {<img src={prismicHomepage.node.data.section_1[0].image.url} />}
          </div>
        </div>
      </section>
      <section id="press" className="py-20 lg:pt-32">
        <PressSection></PressSection>
      </section>
      <section id="why" className="py-20 lg:pb-32 lg:pt-32">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">
            {prismicHomepage.node.data.section_2_title.text}
          </h2>
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
            {prismicHomepage.node.data.section_2.map((section, index) => (
              <div key={index} className="flex-1 px-3">
                <Card className="mb-8">
                  <p className="font-semibold text-xl">{section.title.text}</p>
                  <p className="mt-4">
                    <PrismicRichText field={section.description.richText} />
                  </p>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="features">
        <div className="container mx-auto text-center hidden md:block lg:block xl:block">
          <h2 className="text-3xl md:text-5xl lg:text-5xl font-semibold">
            {prismicHomepage.node.data.section_3_title.text}
          </h2>
        </div>
        {prismicHomepage.node.data.section_3.map((section, index) => (
          <SplitSection
            key={`explanation-section-${index}`}
            id={`explanation-section-${index}`}
            title={section.title.text}
            description={section.description.richText}
            imageUrl={section.image.url}
            reverseOrder={index % 2}
          />
        ))}
      </section>
      <section id="stats" className="py-20 lg:pt-32">
        <StatsSection></StatsSection>
      </section>
      <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
        <h3 className="text-5xl font-semibold">
          In nur wenigen Schritten zum digitalen Produktberater!
        </h3>
        <p className="mt-8 text-xl font-light pr-16 pl-16">
          Teste Chatchamp und erstelle in wenigen Minuten deinen ersten
          digitalen Produktberater. Kundenzentrierte Beratung zu digitalisieren
          war noch nie so einfach.
        </p>
        <p className="mt-8">
          <Button size="xl" link="http://contact.chatchamp.com/testzugang">
            Jetzt testen
          </Button>
        </p>
      </section>
      <section id="testimonials" className="py-20">
        <div className="container mx-auto">
          <LabelText className="mb-8 text-gray-700 text-center">
            Was unser Kunden über uns sagen 🗣
          </LabelText>
          <div className="flex flex-col md:flex-row md:-mx-3">
            {prismicHomepage.node.data.customer_quotes.map(
              (customer_quote, index) => (
                <div key={index} className="flex-1 px-3">
                  <div className="py-4">
                    <CustomerCard customer={customer_quote} />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </section>
      <Newsletter></Newsletter>
    </Layout>
  )
}

export const query = graphql`
  query IndustryQuery($uid: String) {
    allPrismicIndustryPage(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          uid
          data {
            section_1 {
              title {
                text
              }
              description {
                richText
              }
              image {
                alt
                copyright
                url
                gatsbyImageData
              }
            }
            section_2_title {
              text
            }
            section_2 {
              title {
                text
              }
              description {
                richText
              }
            }
            section_3_title {
              text
            }
            section_3 {
              title {
                text
              }
              description {
                richText
              }
              image {
                alt
                copyright
                url
                gatsbyImageData
              }
            }
            customer_quotes {
              title {
                text
              }
              customer_name {
                text
              }
              customer_position {
                text
              }
              content_type {
                text
              }
              quote {
                text
              }
              avatar {
                alt
                copyright
                url
                gatsbyImageData
              }
              card_image {
                alt
                copyright
                url
                gatsbyImageData
              }
              customer_link {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default IndustryPage
